import { h } from 'preact';
import Carousel from '../Carousel/Carousel';
import Asset from '../Asset/Asset';
import { Axis, MediaSymbolTypes, Transition } from '../../typing/enums';
import { getTransformation } from '../../utils/assetHelpers';
import NavButton from '../NavButton/NavButton';
import { getAltText } from '../../utils/accessibility';
import { CarouselViewerWrapper } from './CarouselViewer.styled';
import { t } from '../../common/text';
import { withContext } from '../../common/context';
import { CAROUSEL_DEFAULTS } from '../../config/defaults';

const CarouselViewer = props => {
  const { config } = props.context;

  const navigationProps = {
    navigation: props.navigation,
    offset: config.selectNavigationOffset(),
    ...config.selectNavigationButtonProps()
  };

  const inPopupWithoutMultiZoom = props.inPopup && !config.selectZoomPropsSteps();

  const swipableItem = [MediaSymbolTypes.VIDEO, MediaSymbolTypes.IMAGE].includes(
    props.items[props.index].mediaType
  );

  const scrollableGallery = config.selectTransition() === Transition.SLIDE;

  return (
    <CarouselViewerWrapper
      data-test="carousel-viewer-wrap"
      borderWidth={config.selectBorderWidth()}
      borderColor={config.selectBorderColor()}
      radius={config.selectRadius()}
    >
      <Carousel
        {...CAROUSEL_DEFAULTS}
        index={props.index}
        perView={1}
        itemWidth={props.width}
        itemHeight={props.height}
        spacing={0}
        transition={config.selectTransition()}
        preventPageScroll={config.selectPreventPageScroll()}
        axis={Axis.HORIZONTAL}
        navigation={false}
        onItemSwipe={props.onItemSwipe || undefined}
        isZoomed={props.zoomState > -1}
        swipableItem={swipableItem}
        scrollableGallery={scrollableGallery}
      >
        {props.items.map((item, index) => {
          // Main view - classic mode
          const altText = getAltText(config, item, index, props.items.length);

          return (
            <Asset
              {...item}
              key={item.assetIndex}
              index={props.index}
              mediaType={item.mediaType}
              resourceType={item.resourceType}
              width={props.width}
              height={props.height}
              useBreakpoint={true}
              inView={index === props.index}
              inPopup={props.inPopup}
              setZoom={props.setZoom}
              zoomState={index === props.zoomState}
              zoom={config.selectZoom() && !inPopupWithoutMultiZoom}
              transformation={getTransformation(
                { ...props.transformation, ...item.transformation },
                config
              )}
              assetTransformation={item.transformation}
              alt={altText}
            />
          );
        })}
      </Carousel>
      {props.index > 0 ? (
        <NavButton
          {...navigationProps}
          direction="left"
          onClick={props.prevItem}
          position={config.selectNavigationPosition()}
          aria-label={t('previous')}
        />
      ) : null}
      {props.index < props.items.length - 1 ? (
        <NavButton
          {...navigationProps}
          direction="right"
          onClick={props.nextItem}
          position={config.selectNavigationPosition()}
          aria-label={t('next')}
        />
      ) : null}
    </CarouselViewerWrapper>
  );
};

export default withContext(CarouselViewer);
